<template>
  <div id="app">
    <div id="main">
      <!-- Header -->
      <header id="header">
        <h1>L-Video Production Co.</h1>
        <ul class="icons">
          <li>{{ $t('Address') }}: FLAT/RM D BLK B 30/F BILLION CENTRE 1 WANG KWONG ROAD KOWLOON BAY KL</li>
          <li>{{ $t('Email') }}: hkleowedding@gmail.com</li>
          <li> {{ $t('Tel') }}: 852-93812189</li>
        </ul>
      </header>

      <!-- Footer -->
        <footer id="footer">
          <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
          <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
          <ul class="copyright">
            <li>&copy; L-Video Production Co.</li></li>
          </ul>
        </footer>
        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
    </div>
    <div id="viewer">
      <div class="inner">
        <div class="nav-next"></div>
        <div class="nav-previous"></div>
        <div class="toggle"></div>
      </div>
      <div class="slide active">
        <div class="caption"></div>
        <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: '你好，請問有什麼幫到你？',
          disableInput: false,
        }
      ],
      inputReplied: false,
      replyTexts: [
        {
          'question': '攝影服務?',
          'answer': 'L-Video Production Co有提供婚攝服務:半日婚影/全日婚影/註冊/證婚攝影/海外婚紗攝影等，詳情可向我們查詢!歡迎留下閣下聯絡電話號碼，我們會盡快回覆！謝謝',
        },
        {
          'question': '影片服務?',
          'answer': 'L-Video Production Co有提供影片服務:宣傳片/婚禮/或EVENT類別等，詳情可向我們查詢!歡迎留下閣下聯絡電話號碼，我們會盡快回覆！謝謝',
        },
        {
          'question': '圖像服務?',
          'answer': '我們擁有專業團隊, 累積豐富經驗 創新.獨特.穩定的拍攝技巧可提供圖像服務!服務包括:商務人像、影樓拍攝、上門拍攝等!服務詳情歡樂向我們查詢!歡迎留下閣下聯絡電話號碼，我們會盡快回覆！謝謝',
        },
      ],
    }
  },
  methods:{
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      //If using button
      let question = userAction['value'];

      //If using input
      if(!question){
        question = userAction['text'];
      }

      let answer = this.replyTexts.find(function(item){
        return item.question === question;
      });

      let userQuestion = {
        agent: 'user',
        type: 'text',
        text: question,
        disableInput: false,
      };
      let reply = {
        agent: 'bot',
        type: 'text',
        disableInput: false,
      };

      //第一次Reply後將不會將再出現這段文字
      if(!answer){
        if(!this.inputReplied){
          reply['text'] = 'Hello！歡迎來到L-Video Production Co!我們一間攝影拍攝及製作公司，主要提供拍攝活動、婚禮等影片和圖像服務。專業的攝影設備器材、有豐富的拍攝及影片製作經驗，為您進行完整紀錄，留下珍貴回憶!請問閣下想查詢什麼服務？由於查詢數量較多，如未能及時回覆，歡迎留下閣下聯絡電話號碼，我們會盡快回覆！謝謝！';
          reply['type'] = 'button';
          reply['options'] =  [
            {
              text: '攝影服務?',
              value: '攝影服務?',
              action: 'postback'
            },
            {
              text: '影片服務?',
              value: '影片服務?',
              action: 'postback',
            },
            {
              text: '影片服務?',
              value: '影片服務?',
              action: 'postback',
            },
            {
              text: '圖像服務?',
              value: '圖像服務?',
              action: 'postback',
            },
          ];
          this.inputReplied = true;
        }else{
          reply['text'] = '謝謝你的留言！我們會盡快回覆。';
        }
      }else{
        reply['text'] = answer['answer'];
      }
      this.data.push(userQuestion);
      this.data.push(reply);
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board-action{
  //display: none;
  height: 70px;
}
</style>
